const Theme = {
    container: "",
    containerOpen: "",
    input: "h-10 qstext pl-2 w-full transition duration-500 border-b-2 border-white focus:border-orange bg-transparent text-white focus:outline-none",
    inputOpen: "",
    inputFocused: "",
    suggestionsContainer: "relative transition duration-500 bg-blueMarine",
    suggestionsContainerOpen: "border-2 border-white shadow-lg",
    suggestionsList: "",
    suggestion: "",
    suggestionFirst: "",
    suggestionHighlighted: "",
}

export default Theme;
