import * as React from "react";
import {useEffect, useState} from "react";
import * as ReactModal from "react-modal";
import Dropdown from 'react-dropdown';
import '../../styles/dropdown.css';
import {useImmer} from "use-immer";
import * as AutoSuggest from 'react-autosuggest';
import firebase from "gatsby-plugin-firebase";
import {useDocumentData} from "react-firebase-hooks/firestore";
import {toast} from "react-hot-toast";
import {UserData} from "../../util/firebase/types";
import {useAuthState} from "react-firebase-hooks/auth";
import AutoSuggestTheme from '../../styles/autosuggest-theme'
import {useTranslation} from "react-i18next";

const EditClassModal = ({elem, color, isOpen, onCancel, onSuccess}) => {
    const {t} = useTranslation("timetable")

    const [user] = useAuthState(firebase.auth())
    const [value, loading, error] = useDocumentData<UserData>(firebase.firestore().doc("user-data/" + user.uid))
    const [teacherSuggest, setTeacherSuggest] = useState<string[]>([]);
    const [subjectSuggest, setSubjectSuggest] = useState<string[]>([]);

    useEffect(() => {
        if (error != null) {
            toast.error(error.message);
        }
    }, [error])

    function generateNumbers(limit) {
        let array = []
        for (let i = 0; i < limit; i++) {
            array.push(i < 10 ? "0" + i.toString() : i.toString())
        }
        return array
    }

    const [element, setElement] = useImmer(elem);
    const [durMin, setDurMin] = useState(new Date(elem.duration * 1000).toISOString().substr(11, 5).split(":")[1])
    const [durHour, setDurHour] = useState(new Date(elem.duration * 1000).toISOString().substr(11, 5).split(":")[0])
    const [startMin, setStartMin] = useState(new Date(elem.start * 1000).toISOString().substr(11, 5).split(":")[1])
    const [startHour, setStartHour] = useState(new Date(elem.start * 1000).toISOString().substr(11, 5).split(":")[0])

    useEffect(() => {
        setElement(elem)
        setDurMin(new Date(elem.duration * 1000).toISOString().substr(11, 5).split(":")[1])
        setDurHour(new Date(elem.duration * 1000).toISOString().substr(11, 5).split(":")[0])
        setStartMin(new Date(elem.start * 1000).toISOString().substr(11, 5).split(":")[1])
        setStartHour(new Date(elem.start * 1000).toISOString().substr(11, 5).split(":")[0])
    }, [elem, isOpen])
    useEffect(() => {
        let seconds = 0
        seconds += parseInt(durHour) * 3600
        seconds += parseInt(durMin) * 60
        setElement((draft) => {
            draft.duration = seconds;
        })
    }, [durMin, durHour])
    useEffect(() => {
        let seconds = 0
        seconds += parseInt(startHour) * 3600
        seconds += parseInt(startMin) * 60
        setElement((draft) => {
            draft.start = seconds;
        })
    }, [startMin, startHour])
    return (<ReactModal isOpen={isOpen} onRequestClose={onCancel}
                        className="flex justify-center items-center bg-blueMarine border-white border-2 p-10 shadow-lg rounded-lg"
                        overlayClassName="fixed inset-0 flex justify-center items-center bg-translucent">
        <div>
            <div className="flex flex-col space-y-2">
                <div className="flex flex-row justify-center space-x-2 h-10 border-b-2 border-white">
                    <p className="text-white text-2xl ">{t("startHour")}</p>
                    <Dropdown options={generateNumbers(24)} placeholder="Hour" value={startHour}
                              onChange={(e) => setStartHour(e.value)}/>
                    <Dropdown options={generateNumbers(60)} placeholder="Minutes" value={startMin}
                              onChange={(e) => setStartMin(e.value)}/>
                </div>
                <div className="flex flex-row justify-center space-x-2 h-10 border-b-2 border-white">
                    <p className="text-white text-2xl ">{t("duration")}</p>
                    <Dropdown options={generateNumbers(24)} placeholder="Hour" value={durHour}
                              onChange={(e) => setDurHour(e.value)}/>
                    <Dropdown options={generateNumbers(60)} placeholder="Minutes" value={durMin}
                              onChange={(e) => setDurMin(e.value)}/>
                </div>


                <div className="h-10 flex bg-transparent rounded-lg w-full">
                    <div
                        className="flex flex-col justify-center  border-b-2 border-white   items-center">
                        <p className="text-white pl-1 align-middle text-sm border-r-2 border-white px-2">{t("teacher")}</p>
                    </div>
                    <AutoSuggest theme={AutoSuggestTheme} suggestions={teacherSuggest}
                                 getSuggestionValue={(e) => e}
                                 inputProps={{
                                     value: element.teacher,
                                     onChange: (formEvent, changeEvent) => {
                                         setElement((draft) => {
                                             draft.teacher = changeEvent.newValue;
                                         })
                                     }
                                 }}
                                 shouldRenderSuggestions={(value) => value.trim().length > 1}
                                 onSuggestionsFetchRequested={(req) => {
                                     setTeacherSuggest(() => {
                                         if (!loading && value?.autoselect?.teacher != null) {
                                             return value.autoselect.teacher.filter((element) => {
                                                 return element.toLowerCase().includes(req.value.toLowerCase())
                                             })
                                         }
                                         return [];
                                     })
                                 }}
                                 renderInputComponent={(inputProps) =>
                                     (<input {...inputProps}
                                             placeholder={`nume profesor`}
                                     />)}
                                 renderSuggestion={(suggestion, params) => {
                                     return (<div
                                         className={`${params.isHighlighted ? "bg-blueMarineLight text-orange" : "text-white"} p-2`}>
                                         <p>{suggestion}</p></div>)
                                 }}
                                 renderSuggestionsContainer={({containerProps, children}) => (
                                     <div {...containerProps}>
                                         {children}
                                     </div>
                                 )}
                                 alwaysRenderSuggestions={false}
                    />
                </div>
                <div className="h-10 flex bg-transparent rounded-lg w-full">
                    <div
                        className="flex flex-col justify-center  border-b-2 border-white   items-center">
                        <p className="text-white pl-1 align-middle text-sm border-r-2 border-white px-2">{t("subject")}</p>
                    </div>
                    <AutoSuggest theme={AutoSuggestTheme} suggestions={subjectSuggest}
                                 getSuggestionValue={(e) => e}
                                 inputProps={{
                                     value: element.subject,
                                     onChange: (formEvent, changeEvent) => {
                                         setElement((draft) => {
                                             draft.subject = changeEvent.newValue;
                                         })
                                     }
                                 }}
                                 shouldRenderSuggestions={(value) => value.trim().length > 1}
                                 onSuggestionsFetchRequested={(req) => {
                                     setSubjectSuggest(() => {
                                         if (!loading && value?.autoselect?.subject != null) {
                                             return value.autoselect.subject.filter((element) => {
                                                 return element.toLowerCase().includes(req.value.toLowerCase())
                                             })
                                         }
                                         return [];
                                     })
                                 }}
                                 renderInputComponent={(inputProps) =>
                                     (<input {...inputProps}
                                             placeholder={`nume materie`}/>)}
                                 renderSuggestion={(suggestion, params) => {
                                     return (<div
                                         className={`${params.isHighlighted ? "bg-blueMarineLight text-orange" : "text-white"} p-2`}>
                                         <p>{suggestion}</p></div>)
                                 }}
                                 renderSuggestionsContainer={({containerProps, children}) => (
                                     <div {...containerProps}>
                                         {children}
                                     </div>
                                 )}
                    />
                </div>
                <div className="h-10 flex bg-transparent rounded-lg w-full">
                    <div
                        className="flex flex-col justify-center  border-b-2 border-white   items-center">
                        <p className="text-white pl-1 align-middle text-sm border-r-2 border-white px-2">{t("classType")}</p>
                    </div>
                    <input type="text" placeholder={`tipul de predare`}
                           value={element.type}
                           onChange={e => {
                               setElement((draft) => {
                                   draft.type = e.target.value;
                               })
                           }}
                           readOnly={false}
                           className={`qstext pl-2 w-full transition duration-500 border-b-2 border-white focus:border-orange bg-transparent text-white focus:outline-none`}/>
                </div>
                <div className="h-10 flex bg-transparent rounded-lg w-full">
                    <div
                        className="flex flex-col justify-center  border-b-2 border-white   items-center">
                        <p className="text-white pl-1 align-middle text-sm border-r-2 border-white px-2">{t("translation:day")}</p>
                    </div>
                    <input type="text" placeholder={`readonly`}
                           value={element.day}
                           onChange={e => {
                               setElement((draft) => {
                                   draft.day = e.target.value;
                               })
                           }}
                           readOnly={true}
                           className={`qstext pl-2 w-full transition duration-500 border-b-2 border-white bg-transparent text-white focus:outline-none`}/>
                </div>
                <div className="h-10 flex bg-transparent rounded-lg w-full">
                    <div
                        className="flex flex-col justify-center  border-b-2 border-white   items-center">
                        <p className="text-white pl-1 align-middle text-sm border-r-2 border-white px-2">{t("week")}</p>
                    </div>
                    <input type="text" placeholder={`readonly`}
                           value={element.week}
                           onChange={e => {
                               setElement((draft) => {
                                   draft.week = e.target.value;
                               })
                           }}
                           readOnly={true}
                           className={`qstext pl-2 w-full transition duration-500 border-b-2 border-white  bg-transparent text-white focus:outline-none`}/>
                </div>


            </div>
            <div className="flex mt-2">
                <p className="flex-shrink cursor-pointer bg-gray-300 text-white
                transition duration-500 ease-in-out transform hover:scale-105
                text-lg text-center py-2 px-10 rounded-lg" onClick={onCancel}>{t("translation:cancel")}</p>
                <div className="flex-1 flex-grow w-12"/>
                <p className={`flex-shrink cursor-pointer ${color == null ? "bg-green text-blue-100" : color} 
                    transition duration-500 ease-in-out transform hover:scale-105
                    text-lg text-center py-2 px-10 rounded-lg`} onClick={() => {
                    onSuccess(element)
                    if (!loading) {
                        let autocompletePopulation = ["teacher", "subject"];
                        let newAutoSelect = {}
                        for (elem of autocompletePopulation) {
                            if (element[elem] == "") continue;
                            let auto = value?.autoselect?.[elem] != null ? value?.autoselect?.[elem] : [];
                            if (auto.lastIndexOf(element[elem]) == -1) {
                                auto.push(element[elem]);
                            }
                            newAutoSelect[elem] = auto;
                        }
                        firebase.firestore().doc("user-data/" + user.uid).set({autoselect: newAutoSelect}, {merge: true})
                    }
                }}>{t("translation:save")}</p>
            </div>
        </div>
    </ReactModal>)
}

export default EditClassModal;
