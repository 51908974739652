import * as React from "react";
import { Dispatch } from "react";
import { MdContentCopy, MdDelete, MdEdit } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { Day, TClassSimple } from "../../util/enpoints/types";
import { TimetableAction } from "../../hooks/useTimetable";

type Props = {
  week: number;
  day: Day;
  dayData: TClassSimple[];
  setDayData: Dispatch<TimetableAction>;
  openEditModal: (element: TClassSimple, callback: (elem: TClassSimple) => void) => void;
  openDeleteModal: (content: string, callback: () => void) => void;
  alternativeStyle?: boolean;
};
const TimetableDay: React.FC<Props> = ({
  week,
  day,
  dayData,
  setDayData,
  openEditModal,
  openDeleteModal,
  alternativeStyle = false,
}) => {
  const { t } = useTranslation(["timetable", "translation", "crud"]);
  return (
    <div className="flex flex-row  flex-wrap w-full">
      <p
        className={`w-44 text-white text-center text-2xl md:text-3xl border-r-2 border-l-2 transition duration-250 ease-in-out border-b-2 border-white select-none ${
          alternativeStyle ? "rounded-b-lg" : ""
        }`}
      >
        {t(`translation:${day}`)}
      </p>
      <button
        type="button"
        className={`w-20 cursor-pointer text-white  text-center text-2xl md:text-3xl border-r-2 transition duration-250 ease-in-out hover:text-orange border-b-2 border-white ${
          alternativeStyle ? "rounded-b-lg" : ""
        }`}
        onClick={() => {
          setDayData({ type: "add", week, day });
        }}
      >
        +
      </button>
      {dayData.map((elem, index) => (
        <div
          className={`flex flex-row justify-center  border-b-2 border-l-2 border-white space-x-2 px-4 ${
            alternativeStyle ? "rounded-b-lg" : ""
          }`}
          key={index.toString()}
        >
          <p className="text-white text-2xl w-full ">
            {elem.subject} {new Date(elem.start * 1000).toISOString().substr(11, 5)} -{" "}
            {new Date((elem.start + elem.duration) * 1000).toISOString().substr(11, 5)}{" "}
          </p>

          <button
            type="button"
            className="text-white text-2xl transition duration-250 ease-in-out transform hover:scale-110 hover:text-orange mb-2 "
            onClick={() =>
              openEditModal(elem, (elem) => {
                setDayData({ type: "replace", week, day, index, cl: elem });
              })
            }
          >
            <MdEdit />
          </button>
          <button
            type="button"
            className="text-white text-2xl transition duration-250 ease-in-out transform hover:scale-110 hover:text-orange mb-2 "
            onClick={() =>
              openDeleteModal(t("crud:duplicateClassOnOtherWeek"), () => {
                const copy = JSON.parse(JSON.stringify(elem));
                copy.week = copy.week === 0 ? 1 : 0;
                setDayData({ type: "add", week: copy.week, day: copy.day, cl: copy });
              })
            }
          >
            <MdContentCopy />
          </button>
          <button
            type="button"
            className="text-white text-2xl transition duration-250 ease-in-out transform hover:scale-110 hover:text-red mb-2"
            onClick={() =>
              openDeleteModal(t("timetable:deletePrompt", { day: t(`translation:${day}`) }), () => {
                setDayData({ type: "remove", week, day, index });
              })
            }
          >
            <MdDelete />
          </button>
        </div>
      ))}
    </div>
  );
};

export default TimetableDay;
