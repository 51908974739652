import * as React from "react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdRedo, MdUndo } from "react-icons/md";
import ConfirmModal from "../modal/confirm";
import EditClassModal from "../modal/editClass";
import TimetableDay from "./TimetableDay";
import { TClassSimple, TimetableSimple } from "../../util/enpoints/types";
import { TimetableAction } from "../../hooks/useTimetable";

type Props = {
  timeTable: TimetableSimple<TClassSimple>;
  dispatchTimeTable: React.Dispatch<TimetableAction<TClassSimple, TimetableSimple<TClassSimple>>>;
  handleUndo: () => void;
  handleRedo: () => void;
  canUndo: boolean;
  canRedo: boolean;
  title: string;
  alternativeStyle?: boolean;
};

const EditTimetable: React.FC<Props> = ({
  timeTable,
  dispatchTimeTable,
  title,
  alternativeStyle = false,
  handleUndo,
  handleRedo,
  canUndo,
  canRedo,
}) => {
  const { t } = useTranslation(["timetable", "translation"]);

  const [week, setWeek] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [onModalCancel, setOnModalCancel] = useState(null);
  const [onModalSuccess, setOnModalSuccess] = useState(null);
  const [modalContent, setModalContent] = useState("");
  const [modalSuccessColor, setModalSuccessColor] = useState("bg-red text-white");
  const [isEditModalOpen, setEditIsModalOpen] = useState(false);
  const [onEditModalCancel, setEditOnModalCancel] = useState(null);
  const [onEditModalSuccess, setEditOnModalSuccess] = useState(null);
  const [modalEditSuccessColor, setEditModalSuccessColor] = useState("bg-red text-white");
  const [modalEditElem, setModalEditElem] = useState({ duration: 0, start: 0 });
  const openEditModal = useCallback((element: TClassSimple, callback: (element: TClassSimple) => void) => {
    setEditOnModalCancel(() => () => setEditIsModalOpen(false));
    setModalEditElem(element);
    setEditOnModalSuccess(() => (element) => {
      setEditIsModalOpen(false);
      callback(element);
    });
    setEditModalSuccessColor("bg-red text-white");
    setEditIsModalOpen(true);
  }, []);

  const openDeleteModal = useCallback((content: string, callback: () => void) => {
    setOnModalCancel(() => () => setIsModalOpen(false));
    setOnModalSuccess(() => () => {
      setIsModalOpen(false);
      callback();
    });
    setModalSuccessColor("bg-red text-white");
    setModalContent(content);
    setIsModalOpen(true);
  }, []);

  return (
    <div className="flex flex-col">
      <ConfirmModal
        isOpen={isModalOpen}
        onCancel={onModalCancel}
        onSuccess={onModalSuccess}
        content={modalContent}
        color={modalSuccessColor}
      />
      <EditClassModal
        elem={modalEditElem}
        isOpen={isEditModalOpen}
        onCancel={onEditModalCancel}
        onSuccess={onEditModalSuccess}
        color={modalEditSuccessColor}
      />
      <div className="flex flex-row h-28 w-full justify-center space-x-4 items-center">
        <p className={`transition text-center duration-500 ease-in-out text-white cursor-default text-3xl `}>{title}</p>
      </div>
      <div className="flex flex-row h-28 w-full justify-center space-x-4 items-center">
        <button
          type="button"
          className={`transition duration-500 ease-in-out text-white cursor-pointer text-2xl border-b-2 ${
            alternativeStyle ? "rounded-full px-6" : ""
          } ${week === 0 ? "border-orange" : "border-white"}`}
          onClick={() => setWeek(0)}
        >
          {t("timetable:week")} 1
        </button>
        <button
          type="button"
          className={`transition duration-500 ease-in-out text-white cursor-pointer text-2xl border-b-2 ${
            alternativeStyle ? "rounded-full px-6" : ""
          } ${week === 1 ? "border-orange" : "border-white"}`}
          onClick={() => setWeek(1)}
        >
          {t("timetable:week")} 2
        </button>
      </div>
      <div className="flex flex-col justify-evenly h-full w-full">
        <TimetableDay
          week={week}
          day="monday"
          dayData={timeTable.weeks[week].monday}
          setDayData={dispatchTimeTable}
          openEditModal={openEditModal}
          openDeleteModal={openDeleteModal}
          alternativeStyle={alternativeStyle}
        />
        <TimetableDay
          week={week}
          day="tuesday"
          dayData={timeTable.weeks[week].tuesday}
          setDayData={dispatchTimeTable}
          openEditModal={openEditModal}
          openDeleteModal={openDeleteModal}
          alternativeStyle={alternativeStyle}
        />
        <TimetableDay
          week={week}
          day="wednesday"
          dayData={timeTable.weeks[week].wednesday}
          setDayData={dispatchTimeTable}
          openEditModal={openEditModal}
          openDeleteModal={openDeleteModal}
          alternativeStyle={alternativeStyle}
        />
        <TimetableDay
          week={week}
          day="thursday"
          dayData={timeTable.weeks[week].thursday}
          setDayData={dispatchTimeTable}
          openEditModal={openEditModal}
          openDeleteModal={openDeleteModal}
          alternativeStyle={alternativeStyle}
        />
        <TimetableDay
          week={week}
          day="friday"
          dayData={timeTable.weeks[week].friday}
          setDayData={dispatchTimeTable}
          openEditModal={openEditModal}
          openDeleteModal={openDeleteModal}
          alternativeStyle={alternativeStyle}
        />
        <TimetableDay
          week={week}
          day="saturday"
          dayData={timeTable.weeks[week].saturday}
          setDayData={dispatchTimeTable}
          openEditModal={openEditModal}
          openDeleteModal={openDeleteModal}
          alternativeStyle={alternativeStyle}
        />
        <TimetableDay
          week={week}
          day="sunday"
          dayData={timeTable.weeks[week].sunday}
          setDayData={dispatchTimeTable}
          openEditModal={openEditModal}
          openDeleteModal={openDeleteModal}
          alternativeStyle={alternativeStyle}
        />
      </div>
      <div className={`flex items-center justify-start gap-4 ${alternativeStyle ? "" : "px-4"} pt-3`}>
        <button
          type="button"
          className={`flex items-center gap-2 transition duration-500 ease-in-out cursor-pointer text-xl border-b-2  ${
            canUndo ? "text-white hover:border-green" : "text-gray-300 border-gray-300 cursor-not-allowed"
          } ${alternativeStyle ? "rounded-full px-6" : "px-3"}`}
          onClick={handleUndo}
          disabled={!canUndo}
        >
          <MdUndo />
          <span>Undo</span>
        </button>
        <button
          type="button"
          className={`flex items-center gap-2 transition duration-500 ease-in-out cursor-pointer text-xl border-b-2 ${
            canRedo ? "text-white hover:border-green" : "text-gray-300 border-gray-300 cursor-not-allowed"
          } ${alternativeStyle ? "rounded-full px-6" : "px-3"}`}
          onClick={handleRedo}
          disabled={!canRedo}
        >
          <MdRedo />
          <span>Redo</span>
        </button>
      </div>
    </div>
  );
};

export default EditTimetable;
