import * as React from "react";
import * as ReactModal from "react-modal";
import { useTranslation } from "react-i18next";

type Props = {
  color?: string;
  isOpen: boolean;
  onCancel?(event: React.MouseEvent | React.KeyboardEvent): void;
  onSuccess?(event: React.MouseEvent | React.KeyboardEvent): void;
  content: string;
};
const ConfirmModal: React.FC<Props> = ({ color, isOpen, onCancel, onSuccess, content }) => {
  const { t } = useTranslation();
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onCancel}
      className="flex justify-center items-center bg-blueMarine border-white border-2 p-10 shadow-lg rounded-lg"
      overlayClassName="fixed inset-0 flex justify-center items-center bg-translucent"
    >
      <div>
        <div className="mb-10">
          <p className="text-white">{content}</p>
        </div>
        <div className="flex">
          <button
            type="button"
            className="flex-shrink cursor-pointer bg-gray-300 text-white
                transition duration-500 ease-in-out transform hover:scale-105
                text-lg text-center py-2 px-10 rounded-lg"
            onClick={onCancel}
          >
            {t("cancel")}
          </button>
          <div className="flex-1 flex-grow w-12" />
          <button
            type="button"
            className={`flex-shrink cursor-pointer ${color == null ? "bg-green text-blue-100" : color} 
                    transition duration-500 ease-in-out transform hover:scale-105
                    text-lg text-center py-2 px-10 rounded-lg`}
            onClick={onSuccess}
          >
            {t("continue")}
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

export default ConfirmModal;
