import { applyPatches, enablePatches, Patch, produceWithPatches } from "immer";
import { Dispatch, useCallback, useRef, useState } from "react";
import { WritableDraft } from "immer/dist/types/types-external";
import { Day, getEmptyTimetable, TClassSimple, TimetableSimple } from "../util/enpoints/types";

enablePatches();
export type TimetableAction<C extends TClassSimple, T extends TimetableSimple<C>> =
  | {
      type: "add";
      week: number;
      day: Day;
      cl: C; // class
    }
  | {
      type: "remove";
      week: number;
      day: Day;
      index: number;
    }
  | {
      type: "replace";
      week: number;
      day: Day;
      index: number;
      cl: C; // class
    }
  | {
      type: "reset";
    }
  | {
      type: "set";
      timeTable: T;
    }
  | {
      type: "apply_patches";
      patches: Patch[];
    };

// can't replace in draft TimetableSimple with T
function timetableRecipe<C extends TClassSimple, T extends TimetableSimple<C>>(
  draft: WritableDraft<TimetableSimple<TClassSimple>>,
  action: TimetableAction<C, T>
): T {
  switch (action.type) {
    case "add": {
      let { cl } = action;
      if (cl == null) {
        cl = {
          start: 28800,
          duration: 3600,
          teacher: "",
          subject: "",
          type: "Seminar",
          day: action.day,
          week: action.week,
        };
      }
      const index = draft.weeks[action.week][action.day].findIndex((element) => element.start > cl.start);
      if (index === -1) {
        draft.weeks[action.week][action.day].push(cl);
        break;
      }
      draft.weeks[action.week][action.day].splice(index, 0, cl);
      break;
    }
    case "remove":
      draft.weeks[action.week][action.day].splice(action.index, 1);
      break;
    case "replace": {
      draft.weeks[action.week][action.day].splice(action.index, 1);
      const index = draft.weeks[action.week][action.day].findIndex((element) => element.start > action.cl.start);
      if (index === -1) {
        draft.weeks[action.week][action.day].push(action.cl);
        break;
      }
      draft.weeks[action.week][action.day].splice(index, 0, action.cl);
      break;
    }
    case "reset":
      return getEmptyTimetable<C, T>();
    case "set":
      return action.timeTable;
    case "apply_patches": {
      const { patches } = action;
      return applyPatches(draft, patches) as T; // something not ok with types again
    }
    default:
      console.error("Wrong action type");
      break;
  }
}

const patchGeneratingReducer = produceWithPatches(timetableRecipe);
/**
 * @returns State, Dispatch Function, UndoFunction, RedoFunction, if it can undo, if it can redo
 */
export default function useTimetable<C extends TClassSimple, T extends TimetableSimple<C>>(): [
  T,
  Dispatch<TimetableAction<C, T>>,
  () => void,
  () => void,
  boolean,
  boolean
] {
  const [state, setState] = useState<T>(() => getEmptyTimetable<C, T>());
  const [[canUndo, canRedo], setUndoability] = useState([false, false]);
  const undoStack = useRef([]);
  const undoStackPointer = useRef(-1);

  const recalculateUndoability = useCallback<() => [boolean, boolean]>(
    () => [undoStackPointer.current >= 0, undoStackPointer.current < undoStack.current.length - 1],
    []
  );

  const dispatch = useCallback(
    (action: TimetableAction<C, T>, undoable = true) => {
      setState((currentState) => {
        const [newState, patches, inversePatches] = patchGeneratingReducer(currentState, action);
        if (undoable && !["reset", "set"].includes(action.type)) {
          console.log("adding to stack");
          const pointer = ++undoStackPointer.current;
          undoStack.current.length = pointer + 1;
          undoStack.current[pointer] = { patches, inversePatches };
          setUndoability(recalculateUndoability());
        }
        if (["reset", "set"].includes(action.type)) {
          console.log("resetting");
          undoStackPointer.current = -1;
          undoStack.current = [];
          setUndoability(recalculateUndoability());
        }
        return newState as T; // new state type should have been inferred correctly, unfortunately patchGeneratingReducer doesn't keep generics
      });
    },
    [recalculateUndoability]
  );

  const handleUndo = (): void => {
    if (undoStackPointer.current < 0) return;
    const patches = undoStack.current[undoStackPointer.current].inversePatches;
    dispatch({ type: "apply_patches", patches }, false);
    undoStackPointer.current--;
    setUndoability(recalculateUndoability());
  };

  const handleRedo = (): void => {
    if (undoStackPointer.current === undoStack.current.length - 1) return;
    undoStackPointer.current++;
    const { patches } = undoStack.current[undoStackPointer.current];
    dispatch({ type: "apply_patches", patches }, false);
    setUndoability(recalculateUndoability());
  };
  return [state, dispatch, handleUndo, handleRedo, canUndo, canRedo];
}
